import request from "@/Rest";
import { USER_URLS } from "@/Rest/api/urls";
import { LOCATION_URLS, ANALYTICS_URLS } from "@/Rest/api/urls";

export async function totalUsers() {
  try {
    const { data } = await request.GET(USER_URLS.totalUsers);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function userGameStats(id) {
  try {
    const { data } = await request.GET(USER_URLS.userGameStats(id));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function userTrialAverage(id) {
  try {
    const { data } = await request.GET(USER_URLS.userTrialAverage(id));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function userOculusLogins(id) {
  try {
    const { data } = await request.GET(USER_URLS.userOculusLogins(id));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getUsersList() {
  try {
    const { data } = await request.GET(USER_URLS.getUsers);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getListMessages() {
  try {
    const { data } = await request.GET(USER_URLS.getMessages);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getNumberDependents(id) {
  try {
    const { data } = await request.GET(USER_URLS.getDependents(id));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getGTMEvents(id) {
  try {
    const { data } = await request.GET(USER_URLS.getGTMEvents(id));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function updateGTMEvents(id, event) {
  try {
    const { data } = await request.PATCH(USER_URLS.updateGTMEvents(id), event);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getDependents(id) {
  try {
    const { data } = await request.GET(USER_URLS.getAllDependents(id));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function activateDeactivateProfile(id, is_active) {
  try {
    const { data } = await request.PATCH(
      USER_URLS.activateDeactivateProfile(id),
      {
        is_active,
      }
    );

    return { data };
  } catch (error) {
    throw new Error("You don't have seats");
  }
}

export async function editUser(id, body) {
  try {
    const { data } = await request.PATCH(USER_URLS.editUser(id), body);

    return { data };
  } catch (error) {
    const { register_user, phone_number } = error.response.data;

    if (register_user) {
      throw new Error("This email already exists");
    } else if (phone_number) {
      throw new Error("Invalid phone number");
    }

    throw new Error(error);
  }
}

export async function getUserProfiles(id) {
  const { data } = await request.GET(USER_URLS.getAllDependents(id));

  return { data };
}

export async function getUserAssessmentReports(id) {
  const { data } = await request.GET(USER_URLS.getAssessmentReports(id));

  return { data };
}

export async function getAssesmentReportByFileName(name, fileName) {
  const { data } = await request.GET(
    USER_URLS.getAssesmentReportByFileName(name, fileName)
  );

  return { data };
}

export async function getProfiles() {
  try {
    const { data } = await request.GET(USER_URLS.getProfiles);

    return data.dependants;
  } catch (error) {
    throw new Error(error);
  }
}

export async function editPlayer(id, body) {
  try {
    await request.PATCH(USER_URLS.editPlayer(id), body);
  } catch (error) {
    const { username, phone_number } = error.response.data;

    if (username) {
      throw new Error("This username already exists");
    } else if (phone_number) {
      throw new Error("Invalid phone number");
    }

    throw new Error(error);
  }
}

export async function getUserPaymentDetails(location, page = 1) {
  try {
    const params = { location };
    const { data } = await request.GET(LOCATION_URLS.getPaymentDetails(page), { params });
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getUserAssessmentScores(location, platform) {
  try {
    const params = { location, platform };
    const { data } = await request.GET(LOCATION_URLS.userAssessmentScore, { params });
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getUsersOverview() {
  try {
    const { data } = await request.GET(ANALYTICS_URLS.getUsersOverview);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getSubscriptionProvider() {
  try {
    const { data } = await request.GET(USER_URLS.getSubscriptionProvider);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getFreeTrialData() {
  try {
    const { data } = await request.GET(USER_URLS.getFreeTrialData);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function createFreeTrialUserPaymentInstance() {
  try {
    await request.POST(USER_URLS.createFreeTrialUserPaymentInstance);
    return
  } catch (error) {
    throw new Error(error);
  }
}
